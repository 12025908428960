const backendURL = process.env.REACT_APP_BACKEND_URL;

export default class APIService {
  // Gets the first page information like org name and extra org details

  static async getOrgInfo(formInput) {
    console.log(backendURL);
    const response = await fetch(
      `${backendURL}/api/tr/get/orginfo/${formInput}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        },
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((res) => res);
    return response;
  }

  // Validate User Type
  static async validateUserType(formUserType) {
    const response = await fetch(`${backendURL}/api/tr/validate/usertype`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
      },
      credentials: "include",
      body: JSON.stringify(formUserType),
    })
      .then((res) => res.json())
      .then((res) => res);

    return response;
  }

  // Validates the company info
  static async validateCompany(formInput) {
    const response = await fetch(`${backendURL}/api/tr/validate/company`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
      },
      credentials: "include",
      body: JSON.stringify(formInput),
    }).then((res) => res.json());
    return response;
  }

  static async validateAdminInfo(payload) {
    const response = await fetch(`${backendURL}/api/tr/validate/admins`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => res);

    return response;
  }

  static async validateTesterInfo(payload) {
    const response = await fetch(`${backendURL}/api/tr/validate/testers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => res);

    return response;
  }

  static async validateTestKitInfo(payload) {
    const response = await fetch(`${backendURL}/api/tr/validate/testkits`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => res);
    return response;
  }

  static async SubmitForm(formInput) {
    const response = await fetch(`${backendURL}/api/tr/submit/all`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(formInput),
    }).then((res) => res.json());
    return response;
  }

  //////////////////////////////////////////////////////SC ADMIN CALLS FROM HERE///////////////////////////////////////////////////////////////////////

  // get csrf on login
  // static async getCSRF() {
  //   const response = await fetch(`${backendURL}/get-csrf`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
  //     },
  //     credentials: "include",
  //   });

  //   return response;
  // }

  // SC admin login
  static async scAdminLogin(email, password) {
    // logger.info(`react backend url: ${backendURL}`);
    console.log(backendURL);
    const response = await fetch(`${backendURL}/api/sc/session/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",

        //  // Include the CSRF token in the headers
      },
      credentials: "include",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    return response;
  }

  static async scAdminGoogleLogin(token) {
    const response = await fetch(`${backendURL}/api/sc/session/google-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
      },
      credentials: "include",
      body: JSON.stringify({
        token: token, // Send the Google token to your backend
      }),
    });

    return response;
  }

  // SC admin logout
  static async scAdminLogout() {
    console.log(backendURL);
    const response = await fetch(`${backendURL}/api/sc/session/user-logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        //
      },
      // credentials: "include",
    });

    return response;
  }

  // create an Organization
  static async createOrg(data, token) {
    const encodedURL = encodeURIComponent(data.orgURL);
    const response = await fetch(
      `${backendURL}/api/sc/submit/organization/${encodedURL}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          //  // Include the CSRF token in the headers
        },
        credentials: "include",
        body: JSON.stringify({
          orgInfo: data,
        }),
      }
    ).then((res) => res.json());

    console.log(response);
    return response;
  }

  // Search for an Org
  static async searchOrg(url, token) {
    const response = await fetch(
      `${backendURL}/api/sc/get/search-org?q=${url}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    return response;
  }

  static async getOrgInfoSCA(formInput, token) {
    const response = await fetch(
      `${backendURL}/api/sc/get/orginfo/${formInput}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((res) => res);
    return response;
  }

  // edit organization details
  static async editOrg(orgName, formInput, token) {
    console.log(orgName);
    const encodedURL = encodeURIComponent(orgName);

    formInput.orgName = orgName;

    const response = await fetch(
      `${backendURL}/api/sc/submit/editorganization/${encodedURL}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          //
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        body: JSON.stringify(formInput),
      }
    )
      .then((res) => res.json())
      .then((res) => res);
    return response;
  }

  // delete org
  static async deleteOrg(payload, token) {
    const response = await fetch(
      `${backendURL}/api/sc/submit/delete_organization/${payload.orgName}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          //
        },
        credentials: "include",
        body: JSON.stringify(payload),
      }
    ).then((res) => res.json());
    return response;
  }

  // Fetch Cert Agencies and associated types
  static async getCertAgencies(token) {
    const response = await fetch(
      `${backendURL}/api/sc/get/certification_agencies`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          // "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      }
    ).then((res) => res.json());

    return response;
  }

  // Fetch Cert Types
  static async getCertTypes(token) {
    const response = await fetch(
      `${backendURL}/api/sc/get/certification_types`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,

          // "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      }
    ).then((res) => res.json());

    return response;
  }

  // Create a certification agency
  static async createAgency(payload, token) {
    const response = await fetch(
      `${backendURL}/api/sc/submit/certification_agency/${payload.agency}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          //  // Include the CSRF token in the headers
        },
        credentials: "include",
        body: JSON.stringify(payload),
      }
    ).then((res) => res.json());

    return response;
  }

  // Create a certification type
  static async createType(payload, token) {
    const response = await fetch(
      `${backendURL}/api/sc/submit/certification_type/${payload.type}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          //  // Include the CSRF token in the headers
        },
        credentials: "include",
        body: JSON.stringify(payload),
      }
    ).then((res) => res.json());

    return response;
  }

  // Create a certification type association
  static async createTypeAssociation(data, token) {
    const response = await fetch(
      `${backendURL}/api/sc/submit/certification_type_link/${data.agencyID}/${data.typeID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          //  // Include the CSRF token in the headers
        },
        credentials: "include",
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());

    return response;
  }

  // Delete a certification agency
  static async deleteAgency(payload, token) {
    const response = await fetch(
      `${backendURL}/api/sc/submit/delete_certification_agency/${payload.agency}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          //  // Include the CSRF token in the headers
        },
        credentials: "include",
        body: JSON.stringify(payload),
      }
    ).then((res) => res.json());

    return response;
  }

  // Delete a certification type
  static async deleteType(payload, token) {
    const response = await fetch(
      `${backendURL}/api/sc/submit/delete_certification_type/${payload.type}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          //  // Include the CSRF token in the headers
        },
        credentials: "include",
        body: JSON.stringify(payload),
      }
    ).then((res) => res.json());

    return response;
  }

  static async getOrgReport(payload, token) {
    const response = await fetch(
      `${backendURL}/api/sc/get/get_reports/${payload.orgId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        body: JSON.stringify(payload),
      }
    );

    return response;
  }

  static async getOrgByStatus(status, token) {
    const response = await fetch(
      `${backendURL}/api/sc/get/org_status/${status}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    ).then((res) => res.json());
    return response;
  }

  static async getTotalSignUpsCount(orgID, token) {
    const response = await fetch(
      `${backendURL}/api/sc/get/orgsignups/${orgID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    ).then((res) => res.json());
    return response;
  }

  static async getAllOrgsForReports(token) {
    const response = await fetch(`${backendURL}/api/sc/get/report_orgs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    }).then((res) => res.json());
    return response;
  }

  static async uploadDoc(payload, orgHash) {
    // Payload should be the raw file.
    // Attach the file to a form data object, the form data object
    // will be sent to the backend.

    const formData = new FormData();
    formData.append("file", payload);

    formData.append("orgHash", orgHash);

    const response = await fetch(`${backendURL}/api/uploads/docUpload`, {
      method: "POST",
      headers: {},
      // credentials: "include",
      body: formData,
    }).then((res) => res.json());

    // Fake Success Response:
    // const response = {
    //   "message": {
    //     "url": `https://fake-image.storage/${payload.name}`
    //   },
    //   "code": "SUCCESS"
    // }

    // Fake Error Response:
    // const response = {
    //   'message': {
    //       'file_error': 'No file found.'
    //   },
    //   'code': 'FILE ERROR'
    //   }

    return response;
  }

  static async emailFile(email, orgName, selectedReport, token) {
    // Payload should be the raw file.
    // Attach the file to a form data object, the form data object
    // will be sent to the backend.

    const payload = {
      email: email,
      org_name: orgName,
      selected_report: selectedReport,
    };

    const response = await fetch(
      `${backendURL}/api/sc/get/send_email/${orgName}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((res) => res);
    return response;
  }

  static async testAuth(token) {
    const response = await fetch(`${backendURL}/api/sc/get/testing_route`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    }).then((res) => res.json());
    //  console.log(response.json());

    return response;
  }

  static async verifyAuth(token) {
    const response = await fetch(`${backendURL}/api/sc/get/verify_auth`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    }).then((res) => res.json());
    //  console.log(response.json());

    return response;
  }

  static async fetchUsers(payload, token) {
    const response = await fetch(`${backendURL}/api/sc/get/users_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: JSON.stringify(payload),
    }).then((res) => res.json());

    return response;
  }
}
