import React, { useState, useEffect } from "react";
import "./SCAdmin_Auth.css";
import APIService from "../../APIService";
import { auth, googleProvider } from "./FireBase"; // Adjust the path as necessary
import { signInWithPopup } from "firebase/auth";

const LoginForm = ({ onLoginSuccess }) => {
  const [error, setError] = useState("");
  // const [csrfToken, setCsrfToken] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await APIService.scAdminLogin(email, password);
      const res = await response.json();
      if (response.status == 401) {
        setError("Incorrect Email or Password");
      } else if (response.ok) {
        sessionStorage.setItem("authToken", res.verified);
        onLoginSuccess(res.verified);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("An error occurred during login.");
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Automatically get a refreshed token when needed
      const token = await user.getIdToken(true);

      // Send the token to your backend to verify and create a session
      const response = await APIService.scAdminGoogleLogin(token);
      const res = await response.json();

      if (response.ok) {
        sessionStorage.setItem("authToken", res.verified);
        onLoginSuccess(res.verified);
      } else if (res.message === "Unauthorized domain") {
        setError(
          "Unauthorized Domain. Please Sign In With A SwiftComply Email Only"
        );
      } else {
        setError("Google login failed");
      }
    } catch (error) {
      console.error("Error during Google login:", error);
      setError("An error occurred during Google login.");
    }
  };

  return (
    <form className="loginForm" onSubmit={handleSubmit}>
      {error && <p className="error">{error}</p>}
      <label htmlFor="email">Email</label>
      <div className="inputField">
        <input
          type="text"
          name="email"
          className="inputField__input"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <label htmlFor="password">Password</label>
      <div className="inputField">
        <input
          id="passwordField"
          type="password"
          name="password"
          className="inputField__input"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>
      <div className="loginButtonContainer">
        <button className="mainBtn" type="submit">
            LOGIN
        </button>
        <button type="button" className="mainBtn" onClick={handleGoogleLogin}>
            SSO
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
