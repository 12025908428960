import React, { useState } from "react";
import APIService from "../../APIService";
import { NavLink, useNavigate } from "react-router-dom";
import { useToken } from "../../TokenContext";

// not using this -> just a test component
const TestComponent = () => {
  const { currentToken } = useToken();
  console.log("here: ", currentToken);
  const onButtonClick = async (e) => {
    e.preventDefault();
    console.log("hello");
    const res = await APIService.testAuth(currentToken);
    console.log(res);
  };

  return (
    <>
      <main>
        <section>
          <div>
            <p> FocusApp </p>

            <form>
              <div>
                <button onClick={onButtonClick}>Login</button>
              </div>
            </form>

            <p className="text-sm text-white text-center">
              No account yet? <NavLink to="/signup">Sign up</NavLink>
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default TestComponent;
