// FireBase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FB_KEY}`,
  authDomain: `${process.env.REACT_APP_FB_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_FB_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FB_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FB_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FB_APP_ID}`,
};

// console.log(process.env.REACT_APP_FB_KEY);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  hd: "swiftcomply.com",
});

export { auth, googleProvider };
